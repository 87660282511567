.keycontainer
{
    width: 100%;
    /* background-color: aqua; */
    margin-bottom: 10px;
    /* margin: 10px; */
    /* padding: 10px; */
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.kl{
    width: 15%;
    height: 100%;
    /* background-color: black; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.kr{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-c
    olor: black; */
}

.km{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: black; */
}   