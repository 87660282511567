@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.navcontainer{
    /* margin-bottom: 50px; */
    background-color: white;
    z-index: 7;
    position: fixed;
    height: 60px;
    width: 100vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.navinner{
    /* margin-left: 100px; */
    width: 90%;
    display: flex;
    /* justify-content: space-between; */
}

.navinner i {
    margin-right: 10px;
}

/* .navinner span{
    color: indi;
} */

.navinner i {
    color: indigo;
}