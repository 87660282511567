@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

.dashcontainer{
    /* margin-top: 60px; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    height: calc(100vh - 70px);
    /* margin-bottom: 220px; */
    /* background-color: aqua; */
}

.dashinner{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    width: 100%;
   /* background-color: black; */
    /* height: calc(100vh - 70px); */
    height: 100%;
    margin-left: 300px;
   
}

.divider{
    height: 70%;
    width: 1px;
    background-color: rgb(202, 202, 202);
}
.left
{
    width: 40%;
    /* background-color: antiquewhite; */
    height: 100px;
    border-top: 1px solid rgb(223, 221, 221);

}

.leftr{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.right{
    width: 60%;
    
    /* height: 100px; */

    /* background-color: aqua; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-evenly; */
}



.ritem
{
    display: flex;
    flex-direction: column;
    font-family: 'Rubik', sans-serif;
}

.rval
{
   

    font-size: 35px;
    margin-top: 10px;
}

.rvalheading{
    /* font-weight: 300; */
    color: rgb(113, 113, 113);
}

.righttop{
    display: flex;
    
}

.rtele{
    width: 100%;
   

    /* background-color: black; */
    border: 1px solid rgb(223, 221, 221);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 30px; */
    height: 250px;

}

.recharts-wrapper {
  margin : 0 auto;
}

.rtele span{
    color: rgb(82, 82, 82);
    font-weight: 500;
    margin-left: 20px;
}


.rightbottom
{
width: 100%;
height: 500px;
display: flex;
}

.btlt{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.btrt{
    width: 100%;
    height: 100%;
    border: 1px solid rgb(223, 221, 221);
}

.btlt1 ,.btlt2{
    height: 50%;
    height: 50%;
    width: 100%;
    border: 1px solid rgb(223, 221, 221);
    /* width: 100%; */
   

    /* background-color: black; */
    /* border: 1px solid rgb(223, 221, 221); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 30px; */
    /* height: 250px; */
}

.btlt1 span ,.btlt2 span{
    color: rgb(82, 82, 82);
    font-weight: 500;
    margin-left: 20px;
}

.leftsearch{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    justify-content: center;
    align-items: center;
  
    /* height: 100%; */
}

.leftsearch i {
    margin-right: 10px;
    color: rgb(133, 133, 133);
}

.leftsearch input{
    border: 1px solid rgb(223, 221, 221);
    /* height: 25px; */
    background-color: rgb(246, 246, 246);
    border-radius: 10px;
    padding: 10px;
    width: 80%;
}

.rightdeep{
    border: 1px solid rgb(223, 221, 221);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 20px; */
}

.sidenav{
    width: 300px;
    height: 100%;
    background-color: black;
    position: fixed;
    left: 0px;
}

.leftsearcht{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    
}

.leftsearchbt
{
    width: 100%;
    height: 1096px;
    margin-top: 10px;
    overflow: scroll;
}